<template>
  <b-container class="galeria" v-loading="loading">
    <img src="https://arquivos.aquidauana.ms.gov.br/pub/banner-destaque.jpg" v-if="!active" />
    <div class="galeria-title">
      <h3>{{ galeria.gaf_titulo }}</h3>
    </div>
    <div class="galeria-date pb-2">
      {{ galeria.gaf_data | formatDate("LL") }}
    </div>
    <div class="galeria-date pb-2">
      {{ galeria.fotos_creditos && `Créditos: ${galeria.fotos_creditos}` }}
    </div>
    <div class="galeria-desc" v-if="galeria.gaf_descricao">
      <p>{{ galeria.gaf_descricao }}</p>
    </div>
    <div class="see-all pb-3">
      <a href="/galerias" class="text-right"
        ><span class="all-links">Ver outras Galerias</span></a
      >
    </div>

    <div class="galeria-images" v-if="Object.keys(galeria).length > 0">
      <ul>
        <li v-for="(item, index) in galeria.allimage.data" :key="index">
          <v-lazy-image :src="item.imagem" alt="" />
        </li>
        <!-- <li></li> -->
      </ul>
    </div>
    <div class="galeria-pagination" v-if="Object.keys(galeria).length > 0">
      <b-pagination
        align="center"
        v-model="galeria.allimage.current_page"
        pills
        :total-rows="galeria.allimage.total"
        @change="handleChangePage"
        :per-page="galeria.allimage.per_page"
      ></b-pagination>
    </div>
    <div class="see-all pb-3 pt-3">
      <a href="/galerias" class="text-right"
        ><span class="all-links">Ver outras Galerias</span></a
      >
    </div>
  </b-container>
</template>

<script>
import VLazyImage from "v-lazy-image/v2";

export default {
  name: "galeria",
  components: {
    VLazyImage,
  },
  data() {
    return {
      galeria: [],
      loading: false,
      id: "",
      active: false
    };
  },
  methods: {
    async getGaleria(pg = 1) {
      this.loading = true;
      this.galeria = [];
      const offset = 20;
      window.scrollTo({
        top: 0,
      });
      const res = await this.$axios
        .get(`galerias/${this.id}?page=${pg}&offset=${offset}`)
        .catch((e) => {
          console.error("Noticia", e);
        });
      if (res.status === 200) {
        this.galeria = res.data.filter(
          (galeria) => galeria.gaf_cod.toString() === this.id
        )[0];
      }
      this.loading = false;
    },
    handleChangePage(pg) {
      this.getGaleria(pg);
    },
  },
  mounted() {
    this.id = this.$route.params.id;
    this.getGaleria();
  },
};
</script>

<style lang="scss">
.galeria {
  min-height: 300px;
  padding: 30px 20px;
  font-family: Poppins;
  font-size: var(--font-size--default);
  color: var(--color-text-title);
  h3 {
    font-weight: bold;
  }
  .see-all {
    display: flex;
    justify-content: flex-end;
    padding: 0;
  }
  .text-right {
    width: 150px;
    height: 30px;
    background-color: var(--background-color-secondary);
    color: var(--background-color-primary);
    border-radius: 5px;
    align-items: center;
    justify-content: center;
    display: flex;
    &:active,
    &:hover {
      background-color: var(--background-color-hover-buttons) !important;
      text-decoration: none;
    }
  }
  &-desc {
    text-align: left;
    margin: 0 20px;
  }
  &-images {
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }

    li {
      margin: 5px 0 0 5px;
      height: 45vh;
      // flex-grow: 1;
    }
    // li:last-child {
    //   flex-grow: 10;
    // }

    img {
      border: 2px solid;
      max-height: 100%;
      width: 100%;
      object-fit: cover;
      vertical-align: bottom;
    }

    @media (max-aspect-ratio: 1/1) {
      li {
        height: 30vh;
      }
    }
    // Short screens

    @media (max-height: 480px) {
      li {
        height: 80vh;
      }
    }

    // Smaller screens in portrait

    @media (max-aspect-ratio: 1/1) and (max-width: 480px) {
      ul {
        flex-direction: row;
      }

      li {
        height: auto;
        width: 100%;
      }
      img {
        width: 100%;
        max-height: 75vh;
        min-width: 0;
      }
    }
  }

  &-pagination {
    padding-top: 30px;
  }
}
.v-lazy-image {
  filter: blur(10px);
  transition: filter 0.7s;
}
.v-lazy-image-loaded {
  filter: blur(0);
}
</style>
